import classNames from "classnames"
import React, {
  useCallback,
  useEffect,
  useState,
  useRef,
  useLayoutEffect,
  Children,
} from "react"

import { getImageDimensionsFromUrl } from "@utils/image"

/*
  TODO: use Tailwind's resolveConfig function to get these breakpoints from
  our Tailwind config.

  Read more here: https://tailwindcss.com/docs/configuration#referencing-in-java-script
  Comment thread here: https://gitlab.com/yoco/yoco-websites/yoco-com/-/merge_requests/1359#note_1765618069
*/
enum Breakpoint {
  default = "default",
  "sm" = 667,
  "md" = 900,
  "lg" = 1200,
  "xl" = 1512,
}

type columnsPerBreakpoint = {
  [key in Breakpoint]?: number
}

type Props = {
  columnsPerBreakpoint?: columnsPerBreakpoint
  columnClassName?: string
  multiColumnClassName?: string // applies these classNames responsively when number of columns is greater than 1
  className?: string
  children: React.ReactNode
}

const DEFAULT_COLUMNS = 2

const Masonry = ({
  /*
      columnsPerBreakpoint works on a desktop-frist policy for
      breakpoints which is why "default" refers to desktop screens
      and "667" refers to any screen smaller than 667px.
    */
  columnsPerBreakpoint = { default: DEFAULT_COLUMNS, [Breakpoint.sm]: 1 },

  columnClassName,
  multiColumnClassName,
  className,
  children,
  ...props
}: Props) => {
  const [columnCount, setColumnCount] = useState<number>(
    getCurrentBreakpointValue(columnsPerBreakpoint)
  )
  const elementHeightsRef = useRef<number[]>([])
  const containerRef = useRef<HTMLDivElement>(null)
  /*
    Default value for masonryItems is as simple as possible for rendering purposes and does not look correct in
    terms of design (due to time constraints and this implementation requires the height of each child before arranging them).
    TODO - refactor with a better default value so initial render looks more correct
  */
  const [masonryItems, setMasonryItems] = useState(
    <div
      style={{
        width: `${100 / columnCount}%`,
      }}
      className={columnClassName}
    >
      {children}
    </div>
  )

  const handleBreakpointChange = () => {
    setColumnCount(getCurrentBreakpointValue(columnsPerBreakpoint))
  }

  useEffect(() => {
    handleBreakpointChange()

    window.addEventListener("resize", handleBreakpointChange, { passive: true })
    /*
      TODO - maybe re-arrange elements on window resize since the height of elements change
    */

    return () => {
      window.removeEventListener("resize", handleBreakpointChange)
    }
  }, [])

  type MappedElements = React.ReactNode[]
  type MappedColumns = MappedElements[]

  const mapElements = useCallback((): MappedColumns => {
    const columns: MappedColumns = Array.from({ length: columnCount }, () => [])

    if (!elementHeightsRef.current || elementHeightsRef.current.length < 1)
      throw new Error("Failed to arrange Masonry elements. Missing elementsRef")

    const childrenArray = Children.toArray(children)

    const columnHeights = Array.from({ length: columnCount }, () => 0)
    let columnIndex = 0

    for (let index = 0; index < childrenArray.length; index++) {
      columnIndex = columnHeights.indexOf(Math.min(...columnHeights))

      columns[columnIndex].push(childrenArray[index])
      columnHeights[columnIndex] += elementHeightsRef.current[index]
    }

    return columns
  }, [columnCount, children])

  const renderColumns = useCallback(() => {
    const columns = mapElements()

    const columnWidth = `${100 / columns.length}%`

    const columnAttributes = {
      style: {
        width: columnWidth,
      },
      className: classNames(
        columns.length > 1 ? multiColumnClassName : "",
        columnClassName
      ),
    }

    return (
      <>
        {columns.map((column, index) => {
          return (
            <div {...columnAttributes} key={`masonry-column-${index}`}>
              {column.map((element) => element)}
            </div>
          )
        })}
      </>
    )
  }, [columnClassName, multiColumnClassName, mapElements])

  useLayoutEffect(() => {
    const childrenLength =
      children && typeof children === "object"
        ? Object.keys(children).length
        : NaN

    if (isNaN(childrenLength)) {
      throw new Error("Unhandled children type in Masonry")
    }

    // setInterval is needed to make sure all children is rendered before trying to get their height values
    const interval = setInterval(() => {
      if (
        containerRef.current &&
        containerRef.current.children[0].children.length === childrenLength
      ) {
        const elements: number[] = []

        Array.from(containerRef.current.children).forEach((column) => {
          Array.from(column.children).forEach((element) => {
            /*
            TODO - handle images more generically and add handling for images in flex-row
            also somehow ignore SVGs
            */
            // Hack to get height of image before it's fully loaded.
            if (
              element.children.length === 2 &&
              element.children[1].tagName === "IMG"
            ) {
              // Hack. Assumes no image if true
              const imageSrc = element.children[1].getAttribute("src")

              if (imageSrc) {
                const imgDimensions = getImageDimensionsFromUrl(imageSrc)

                return elements.push(
                  element.children[0].getBoundingClientRect().height +
                    parseInt(imgDimensions.height || "0")
                )
              }

              return elements.push(element.getBoundingClientRect().height)
            }

            elements.push(element.getBoundingClientRect().height)
          })
        })

        if (elements.length > 0) {
          clearInterval(interval)
          elementHeightsRef.current = elements
          setMasonryItems(renderColumns())
        }
      }
    }, 100)

    return () => {
      clearInterval(interval)
    }
  }, [containerRef, children, renderColumns])

  // This useEffect relies on setColumnCount triggering a re-render
  useEffect(() => {
    if (elementHeightsRef.current && elementHeightsRef.current.length > 0) {
      setMasonryItems(renderColumns())
    }
  }, [elementHeightsRef, renderColumns])

  return (
    <div {...props} className={className} ref={containerRef}>
      {masonryItems}
    </div>
  )
}

const getCurrentBreakpointValue = (
  columnsPerBreakpoint: columnsPerBreakpoint
) => {
  let matchedBreakpoint = Infinity
  let columns = columnsPerBreakpoint.default || DEFAULT_COLUMNS
  const browserWidth =
    typeof document !== "undefined" ? document.body.clientWidth : 0

  for (const breakpoint in columnsPerBreakpoint) {
    const currentBreakpoint = parseInt(breakpoint)
    const isCurrentBreakpoint =
      currentBreakpoint > 0 && browserWidth <= currentBreakpoint

    if (isCurrentBreakpoint && currentBreakpoint < matchedBreakpoint) {
      matchedBreakpoint = currentBreakpoint
      const breakpointValue = columnsPerBreakpoint[breakpoint as Breakpoint]
      columns = breakpointValue ? breakpointValue : columns
    }
  }

  columns = Math.max(1, columns || 1)

  return columns
}

export default Masonry
